import React from 'react';
import logo from '../assets/img/Asset 1.svg'; // Asegúrate de que el nombre del archivo sea correcto

const HeroSection = () => {
  return (
    <header className="bg-indigo-700 text-white py-16 px-6 lg:px-8">
      <div className="max-w-4xl mx-auto text-center">
        {/* Logo del cliente con un tamaño más grande */}
        <img src={logo} alt="Logo de MY Spanish First" className="mx-auto h-24 mb-4" />
        
        {/* Título principal */}
        <h1 className="mt-4 text-4xl font-bold leading-tight">
          Tu Reporte de Resultados SEO Mensuales
        </h1>
        
        {/* Texto descriptivo con nombres destacados */}
        <p className="mt-4 text-lg text-indigo-100">
          ¡Hola, <span className="font-bold text-white">Karina Carmona</span>! En <span className="font-semibold">KUV Digital</span>, nos enfocamos en cada detalle para ayudarte a alcanzar tus objetivos. Este reporte mensual está diseñado especialmente para mostrarte el impacto de nuestras estrategias de SEO en <span className="font-semibold">myspanishfirst.com</span> y cómo estamos construyendo juntos una presencia digital sólida y en crecimiento.
        </p>
      </div>
    </header>
  );
};

export default HeroSection;
