import React from 'react';
import logo from '../assets/img/kuv-digital-logo.svg';

const Header = () => {
  return (
    <header className="bg-[#020617] py-4 px-6">
      <div className="max-w-7xl mx-auto flex items-center">
        <img src={logo} alt="KUV Digital Logo" className="h-10" />
      </div>
    </header>
  );
};

export default Header;
