import React from 'react';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

// Datos de generación de leads por fuente (datos reales)
const leadData = [
  { name: 'Directo', value: 5 },
  { name: 'Referencia', value: 2 },
  { name: 'Búsqueda Orgánica', value: 1 },
];

// Datos de ejemplo para el gráfico
const chartData = [
  { name: 'Directo', Directo: 5, Referencia: 0, 'Búsqueda Orgánica': 0 },
  { name: 'Referencia', Directo: 5, Referencia: 2, 'Búsqueda Orgánica': 0 },
  { name: 'Búsqueda Orgánica', Directo: 5, Referencia: 2, 'Búsqueda Orgánica': 1 },
];

// Colores para cada fuente
const COLORS = ['#1e3a8a', '#4338ca', '#6366f1'];

const Lead = () => {
  return (
    <section className="bg-white py-16 px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">Generación de Leads</h2>
        <p className="text-lg text-center text-gray-600 mb-12">
          A continuación, se presenta un análisis de las fuentes de generación de leads y cómo se recopilan estos datos.
        </p>

        {/* Sistema de 2 columnas: Gráfico de área porcentual a la izquierda, información a la derecha */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          {/* Columna izquierda: Gráfico de área porcentual */}
          <div className="flex justify-center">
            <ResponsiveContainer width="100%" height={300}>
              <AreaChart data={chartData} stackOffset="expand">
                <XAxis dataKey="name" />
                <YAxis tickFormatter={(value) => `${(value * 100).toFixed(0)}%`} />
                <Tooltip formatter={(value) => `${(value * 100).toFixed(0)}%`} />
                <Area type="monotone" dataKey="Directo" stackId="1" stroke={COLORS[0]} fill={COLORS[0]} />
                <Area type="monotone" dataKey="Referencia" stackId="1" stroke={COLORS[1]} fill={COLORS[1]} />
                <Area type="monotone" dataKey="Búsqueda Orgánica" stackId="1" stroke={COLORS[2]} fill={COLORS[2]} />
              </AreaChart>
            </ResponsiveContainer>
          </div>

          {/* Columna derecha: Descripción y lista de fuentes */}
          <div className="space-y-6">
            <h3 className="text-xl font-semibold text-gray-800">¿Cómo se recopilan estos datos de leads?</h3>
            <p className="text-gray-600">
              Los datos de generación de leads se obtienen mediante el seguimiento de las interacciones de los usuarios con su sitio web y otras plataformas digitales. Se analizan las fuentes de tráfico, las conversiones y otras métricas clave para identificar de dónde provienen los leads y cómo interactúan con su contenido. Esta información es esencial para optimizar las estrategias de marketing y mejorar la captación de clientes potenciales.
            </p>
            {leadData.map((source, index) => (
              <div key={index} className="text-center lg:text-left">
                <p className="text-2xl font-bold text-indigo-700">{source.name}</p>
                <p className="text-gray-600">{source.value} leads</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Lead;
