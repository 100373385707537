import React from 'react';
import { AreaChart, Area, XAxis, YAxis, Tooltip as RechartsTooltip, ResponsiveContainer } from 'recharts';

const visitsData = [
  { date: 'Oct 10', visits: 1 },
  { date: 'Oct 11', visits: 1 },
  { date: 'Oct 15', visits: 3 },
  { date: 'Oct 29', visits: 2 },
  { date: 'Nov 1', visits: 1 },
  { date: 'Nov 5', visits: 2 },
];

const HeroSection = () => {
  return (
    <section className="w-full bg-white py-12 px-4 sm:px-6 lg:px-8 flex flex-col lg:flex-row items-center max-w-7xl mx-auto">
      {/* Gráfico de Visitas Mensuales */}
      <div className="w-full lg:w-1/2 mb-8 lg:mb-0">
        <ResponsiveContainer width="100%" height={250}>
          <AreaChart data={visitsData}>
            <defs>
              <linearGradient id="colorVisits" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#6366F1" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#6366F1" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis dataKey="date" tick={{ fontSize: 12 }} />
            <YAxis tick={{ fontSize: 12 }} />
            <RechartsTooltip />
            <Area type="monotone" dataKey="visits" stroke="#6366F1" fill="url(#colorVisits)" />
          </AreaChart>
        </ResponsiveContainer>
      </div>

      {/* Texto de Introducción */}
      <div className="w-full lg:w-1/2 text-center lg:text-left">
        <p className="text-sm font-semibold text-indigo-600">Nuestro Impacto</p>
        <h1 className="text-3xl sm:text-4xl font-bold text-gray-800 leading-tight mt-2">
          Resultados de Crecimiento para tu Marca
        </h1>
        <p className="mt-4 text-base sm:text-lg text-gray-600">
          Con estrategias de SEO efectivas, estamos impulsando tu marca a nuevas alturas. Nuestro equipo se dedica a asegurar que cada esfuerzo se traduzca en resultados.
        </p>
        <div className="grid grid-cols-2 gap-4 mt-6 text-sm sm:text-base">
          <div>
            <p className="text-2xl sm:text-3xl font-bold text-gray-900">7</p>
            <p className="text-gray-600">Nuevos Usuarios</p>
          </div>
          <div>
            <p className="text-2xl sm:text-3xl font-bold text-gray-900">7</p>
            <p className="text-gray-600">Usuarios Activos</p>
          </div>
          <div>
            <p className="text-2xl sm:text-3xl font-bold text-gray-900">59s</p>
            <p className="text-gray-600">Tiempo Medio de Compromiso</p>
          </div>
          <div>
            <p className="text-2xl sm:text-3xl font-bold text-gray-900">1.2</p>
            <p className="text-gray-600">Sesiones Activas por Usuario</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
