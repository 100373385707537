import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-[#020617] py-4">
      <div className="max-w-7xl mx-auto text-center text-gray-400 text-sm">
        Developed by KUV Digital
      </div>
    </footer>
  );
};

export default Footer;
