import React, { useState } from 'react';

const MotivationalSection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  return (
    <section className="bg-indigo-100 text-indigo-900 py-16 px-4 text-center w-full">
      <div className="max-w-4xl mx-auto">
        <h2 className="text-4xl font-bold leading-tight mb-4">¿Qué Sigue?</h2>
        <p className="text-lg md:text-xl mb-8">
          Seguimos trabajando en el posicionamiento SEO. Ahora que su sitio web está sincronizado con las analíticas de Google y hemos comenzado a recolectar datos, estos son los siguientes pasos para el próximo mes.
        </p>

        <div className="space-x-4 mb-4">
          <button
            onClick={toggleModal}
            className="bg-indigo-600 text-white font-semibold px-6 py-3 rounded-lg shadow-lg hover:bg-indigo-700"
          >
            Ver Progreso
          </button>
        </div>

        <p className="text-indigo-600 font-semibold">Siguiente Reporte 7 Diciembre 2024</p>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75 p-4">
          <div className="relative bg-white rounded-lg shadow-lg p-6 w-full max-w-5xl">
            {/* Botón de Cerrar */}
            <button
              onClick={toggleModal}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 text-xl"
            >
              ✕
            </button>
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-2xl font-bold text-indigo-800">Progreso por Mes</h3>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {/* Mes 1 */}
              <div className="bg-indigo-50 p-4 rounded-lg shadow">
                <h4 className="text-xl font-bold text-indigo-800">Mes 1</h4>
                <ul className="mt-2 space-y-2 text-left">
                  <li className="flex items-center">
                    <span className="w-2 h-2 bg-green-500 rounded-full mr-2"></span>
                    <span>Sitemap creado</span>
                    <span className="ml-auto text-xs bg-green-100 text-green-700 rounded-full px-2 py-1">Completado</span>
                  </li>
                  <li className="flex items-center">
                    <span className="w-2 h-2 bg-green-500 rounded-full mr-2"></span>
                    <span>Cuentas de Google Analytics, Search Console y Business creadas</span>
                    <span className="ml-auto text-xs bg-green-100 text-green-700 rounded-full px-2 py-1">Completado</span>
                  </li>
                  <li className="flex items-center">
                    <span className="w-2 h-2 bg-green-500 rounded-full mr-2"></span>
                    <span>Evaluación de SEO inicial</span>
                    <span className="ml-auto text-xs bg-green-100 text-green-700 rounded-full px-2 py-1">Completado</span>
                  </li>
                </ul>
              </div>

              {/* Mes 2 */}
              <div className="bg-indigo-50 p-4 rounded-lg shadow">
                <h4 className="text-xl font-bold text-indigo-800">Mes 2</h4>
                <ul className="mt-2 space-y-2 text-left">
                  <li className="flex items-center">
                    <span className="w-2 h-2 bg-yellow-500 rounded-full mr-2"></span>
                    <span>Investigación de palabras clave</span>
                    <span className="ml-auto text-xs bg-yellow-100 text-yellow-700 rounded-full px-2 py-1">En curso</span>
                  </li>
                  <li className="flex items-center">
                    <span className="w-2 h-2 bg-yellow-500 rounded-full mr-2"></span>
                    <span>Optimización de contenido</span>
                    <span className="ml-auto text-xs bg-yellow-100 text-yellow-700 rounded-full px-2 py-1">En curso</span>
                  </li>
                  <li className="flex items-center">
                    <span className="w-2 h-2 bg-yellow-500 rounded-full mr-2"></span>
                    <span>Mejora de la estructura de enlaces internos</span>
                    <span className="ml-auto text-xs bg-yellow-100 text-yellow-700 rounded-full px-2 py-1">En curso</span>
                  </li>
                </ul>
              </div>

              {/* Mes 3 */}
              <div className="bg-indigo-50 p-4 rounded-lg shadow">
                <h4 className="text-xl font-bold text-indigo-800">Mes 3</h4>
                <ul className="mt-2 space-y-2 text-left">
                  <li className="flex items-center">
                    <span className="w-2 h-2 bg-gray-500 rounded-full mr-2"></span>
                    <span>Monitoreo de resultados y ajustes</span>
                    <span className="ml-auto text-xs bg-gray-100 text-gray-700 rounded-full px-2 py-1">Pendiente</span>
                  </li>
                  <li className="flex items-center">
                    <span className="w-2 h-2 bg-gray-500 rounded-full mr-2"></span>
                    <span>Creación de backlinks de calidad</span>
                    <span className="ml-auto text-xs bg-gray-100 text-gray-700 rounded-full px-2 py-1">Pendiente</span>
                  </li>
                  <li className="flex items-center">
                    <span className="w-2 h-2 bg-gray-500 rounded-full mr-2"></span>
                    <span>Revisión y optimización continua</span>
                    <span className="ml-auto text-xs bg-gray-100 text-gray-700 rounded-full px-2 py-1">Pendiente</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default MotivationalSection;
